import CustomControl from "./customControl";
import u from "@/lib/util";
import AddressLookupFactory from "@/lib/address/addressLookup";

const SelectControl = async function (options) {
  const magicKeys = {tab: 9, enter: 13, esc: 27, down: 40, up: 38};

  let addressLookup = null;

  function geocode(self) {
    let list = self.options.elements.list;
    let v = self.searchText;
    if (v.length > 2 && v.indexOf(',') > 0) {
      let search = {address: v, bounds: options.bounds, city: options.city};
      addressLookup.lookup(search).then((results) => {
        self.results = results;
        list.innerHTML = '';
        let html = "";
        for (let i = 0; i < results.length; i++) {
          let r = results[i];
          html += "<div id=\"map--option-" + i + "\" class=\"map--search-option\">" + r.location + "</div>";
        }
        list.innerHTML = html;
        list.style.display = 'block';
        return results;
      }, () => {
        // reject
        list.style.display = 'none';
      });
    } else {
      list.style.display = 'none';
    }
  }

  let select = function (self, children) {
    for (let i = 0; i < children.length; i++) {
      let classes = children[i].classList;
      classes.remove("active");
      if (i === self.selectedIdx) classes.add("active");
    }
  }

  options = options || {};
  options = u.merge({
    position: 'topleft',
    content: '<div class="input-group">' +
      '<input id="map--search-input" type="text" class="form-control input-sm" placeholder="Search">' +
      '<div id="map--search-list"></div>' +
      '</div>',
    classes: '',
    init: function () {
      let self = this;
      self.searchText = '';
      self.debouncedQuery = u.debounce(geocode, 500, {leading: true, maxWait: 1000});
      u.focusByID('map--search-input');
    },
    style:
      {
        position: "absolute",
        left: "50px",
        top: "0px",
        width: "200px",
        "z-index": 1000,
        "background-color": "white",
        border: "solid 1px #888888",
        "padding-left": "0.2rem",
        "padding-right": "0.2rem"
      },
    elements: {
      "list": "#map--search-list",
      "input": "#map--search-input"
    },
    events: {
      click: function (e) {
        e.preventDefault();
        e.stopPropagation();

        let self = this;
        let list = self.options.elements.list;
        let input = self.options.elements.input;
        let children = list.children;

        let id = e.target.id;
        if (id && id.indexOf("map--option") === 0) {
          id = id.replace(/map--option-/, "");
          self.selectedIdx = parseInt(id);
          select(self, children);
          setTimeout(() => {
            list.style.display = 'none';
            input.value = '';
            if (self.results && self.results.length > 0) {
              if (self.options.events['selected']) self.options.events['selected'](JSON.parse(JSON.stringify(self.results[self.selectedIdx])));
            }
          }, 800);
        }
      },
      keydown: function (e) {
        let self = this;
        let list = self.options.elements.list;
        let input = self.options.elements.input;
        let children = list.children;
        let keycode = e.keyCode || e.which;
        let target = e.target;
        let v = target.value;

        let select = function (self, children) {
          for (let i = 0; i < children.length; i++) {
            let classes = children[i].classList;
            classes.remove("active");
            if (i === self.selectedIdx) classes.add("active");
          }
        }

        switch (keycode) {
          case magicKeys.esc:
            e.preventDefault();
            e.stopPropagation();
            list.style.display = 'none';
            input.value = '';
            self.selectedIdx = -1;
            if (self.results) self.results.length = 0;
            break;
          case magicKeys.enter:
          case magicKeys.tab:
            e.preventDefault();
            e.stopPropagation();
            list.style.display = 'none';
            input.value = '';
            if (self.results && self.results.length > 0) {
              if (self.selectedIdx < 0) self.selectedIdx = 0;
              if (self.options.events['selected']) self.options.events['selected'](
                JSON.parse(JSON.stringify(self.results[self.selectedIdx]))
              );
            }
            break;
          case magicKeys.up:
            self.selectedIdx--;
            if (self.selectedIdx < 0) self.selectedIdx = 0;
            select(self, children);
            break;
          case magicKeys.down:
            self.selectedIdx++;
            if (self.selectedIdx > children.length) self.selectedIdx--;
            select(self, children);
            break;
          default:
            v += e.key;
            self.searchText = v;
            self.selectedIdx = -1;
            self.debouncedQuery(self);
        }
      }
    }
  }, options);

  addressLookup = await AddressLookupFactory.getDefault();
  addressLookup.init(options);

  return new CustomControl(options);
};

export default SelectControl;
