<template>
  <v-card >
    <div :style="mapStyle()">
      <div class="w-100 h-100" style="position: absolute; bottom:0;" id="map">
      </div>
    </div>
    <v-card-actions>
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters><v-col>Longitude:</v-col><v-col class="text-center">{{data.x}}</v-col></v-row>
          <v-row no-gutters><v-col>Latitude:</v-col><v-col class="text-center">{{data.y}}</v-col></v-row>
        </v-col>
        <v-col v-if="props.returnBounds">
          <v-row no-gutters>
          <v-col>Bounds</v-col><v-col>
          <div>{{JSON.stringify(data.bounds[0]) }}</div>
          <div>{{JSON.stringify(data.bounds[1]) }}</div>
          </v-col>
          </v-row>
        </v-col>
        <v-col v-if="props.returnAddress">
          <v-row no-gutters>
            <v-col>Address</v-col>
            <v-col>
              <div>{{data.location}}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-btn color="indigo-darken-2" variant="flat" class="float-right mt-1 mr-1" @click="onSave">Save</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { reactive, onMounted, defineProps, defineEmits } from 'vue'
import u from '@/lib/util';
import L from "leaflet";
import SelectControl from "@/map/controls/select";
import DialogClose from "@/map/controls/dialogClose";
import CustomControl from "@/map/controls/customControl";
import {mdiClose} from "@mdi/js";
import {db} from "@/lib/db";
import AddressLookupFactory from "@/lib/address/addressLookup";

const GOOGLE_MAPS_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY;

const props=defineProps({
    returnBounds:{
      type:Boolean,
      default: false
    },
    returnAddress:{
      type:Boolean,
      default: false
    },
    useOrgBounds:{
      type:Boolean,
      default:true
    },
    useOrgCity:{
      type:Boolean,
      default:true
    }
});
const emit = defineEmits(['close','save']);

const data = reactive({
  w:1024,
  h:768,
  searchText:"",
  y:0,
  x:0,
  bounds:[[0,0],[0,0]],
  location:null,
  address:{
    address1:'',
    city:'',
    stateCode:'',
    countryCode:'',
    county:'',
    postalCode:''
  }
});

function mapStyle() {
  return "position:relative;min-width:"+parseInt(data.w*0.75)+"px;min-height:"+parseInt(data.h*0.75)+"px"
}

function onSave() {
  let ret={x:data.x,y:data.y};
  if(props.returnBounds) ret.bounds=data.bounds;
  if(props.returnAddress) {
    ret.address=data.address;
    ret.location=data.location;
  }
  emit('save',ret);
}

onMounted(async ()=>{
  data.w = window.innerWidth;
  data.h = window.innerHeight;

  let bounds=null;
  let city=null;
  let center=[35.9250637,-86.8688899];
  let oid = localStorage.getItem("oid");
  await db.org.where({"oid":oid}).each(o=>{
    if(o.x) center[1]=o.x;
    if(o.y) center[0]=o.y;
    if(props.useOrgBounds && o.bounds) bounds=o.bounds;
    if(props.useOrgCity && o.city) city=o.city;
  });

  const map = L.map('map').setView(center, 13);
  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);

  let marker = null;
  let outerBound = null;

  SelectControl({
    apiKey:GOOGLE_MAPS_KEY,
    bounds:bounds,
    city:city,
    events:{
      selected:(select)=>{
        console.log(select);

        u.extend(data,select);
        map.fitBounds(select.bounds);

        if(props.returnBounds) {
          if (outerBound != null) outerBound.remove();
          outerBound = L.rectangle(select.bounds, {color: "#ff7800", weight: 1}).addTo(map);
        }

        if(marker!=null) marker.remove();
        marker=L.marker([select.y,select.x]);
        marker.addTo(map);
      }
    }
  }).then((c)=>{c.addTo(map)});

  DialogClose({
    events:{
      click:()=>{
        emit('close');
      }
    }
  }).addTo(map);
});
</script>

<style lang="scss">
@import "leaflet/dist/leaflet.css";
@import "controls/select";
@import "controls/dialogClose";

:focus-visible {
  outline: none;
  color: black;
}

</style>
