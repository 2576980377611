
let CustomControl = L.Control.extend({
  version: '1.0.1',
  options: {
    position: 'topright',
    init:null,
    id: '',
    title: '',
    classes: '',
    content: '',
    style: {},
    elements: {},
    events: {},
  },
  container: null,
  onAdd: function (map) {
    this.container = L.DomUtil.create('div');
    this.container.id = this.options.id;
    this.container.title = this.options.title;
    this.container.className = this.options.classes;
    this.container.innerHTML = this.options.content;

    for (let option in this.options.style)
    {
      this.container.style[option] = this.options.style[option];
    }

    for (let id in this.options.elements)
    {
      this.options.elements[id] = this.container.querySelector(this.options.elements[id]);
    }

    /* Prevent click events propagation to map */
    L.DomEvent.disableClickPropagation(this.container);

    /* Prevent right click event propagation to map */
    L.DomEvent.on(this.container, 'contextmenu', function (ev)
    {
      L.DomEvent.stopPropagation(ev);
    });

    /* Prevent scroll events propagation to map when cursor on the div */
    L.DomEvent.disableScrollPropagation(this.container);

    let self=this;
    for (let event in this.options.events)
    {
      L.DomEvent.on(this.container, event, this.options.events[event].bind(self), this.container);
    }

    if(this.options.init!==null) {
      this.options.init=this.options.init.bind(this);
      this.options.init();
    }

    return this.container;
  },
  onRemove: function (map) {
    let self=this;
    for (let event in this.options.events)
    {
      L.DomEvent.off(this.container, event, this.options.events[event].bind(self), this.container);
    }
  },
});

let ControlCustomFactory = function (options) {
  return new CustomControl(options);
};

export default ControlCustomFactory;
