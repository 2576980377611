import CustomControl from "./customControl";
import u from "@/lib/util";
import {mdiClose} from "@mdi/js";

const DialogClose = function (options) {
  options=options||{};
  options=u.merge({
    position: 'topright',
    content : '<i class="v-icon notranslate v-theme--light v-icon--size-default map--close-button">' +
      '<svg class="v-icon__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true"><path d="'+mdiClose+'"></path></svg>' +
      '</i>'
  },options);

  return new CustomControl(options);
};

export default DialogClose;
