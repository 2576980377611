<template>
  <top-nav :theme="data.theme" :module="data.sectionMode" @theme="onChangeTheme">
    <div class="w-100 h-100 home" id="map">

    </div>
  </top-nav>
</template>

<script setup>
import { ref, reactive, onMounted, defineProps, watch } from 'vue'
import TopNav from "@/components/topNav";
import L from "leaflet";
import {db} from "@/lib/db";
import u from '@/lib/util';
import ev from '@/lib/event';

const props=defineProps({
});

const list = reactive([]);

const data = reactive({
  oid:'',
  theme:'dark',
  org:{}
});

let map = null;

function onChangeTheme() {
  data.theme=data.theme==="dark"?"light":"dark";
  localStorage.setItem('theme',data.theme);
}

function setLocation(oid) {
  for(let i=0;i<list.length;i++) {
    if(list[i].oid==oid){
      u.cleanExtend(data.org,list[i]);
      break;
    }
  }

  let latlng = [35.9250637,-86.8688899];
  let org=data.org;
  if(org.x) {
    latlng=[org.y,org.x];
  }

  if(map!==null) {
    if(org.bounds && org.bounds.length>1) map.fitBounds(org.bounds);
    else map.panTo(latlng);
  }

  return latlng;
}

onMounted(async ()=>{
  await db.reload(list,"org",'name');
  ev.on("oidChange","panel",setLocation);

  data.theme=localStorage.getItem('theme') || 'dark';
  let oid=data.oid=localStorage.getItem('oid');

  let latlng = setLocation(oid);

  map = L.map('map').setView(latlng, 13);
  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);

  setLocation(oid);

});
</script>

<style lang="scss">
@import "leaflet/dist/leaflet.css";
</style>
